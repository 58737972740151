import {Directive, TemplateRef, Input} from '@angular/core';

@Directive({
  standalone: true,
  selector: '[tab]',
})
export class TabDirective {
  @Input({alias: 'tab'}) label = '';
  constructor(public template: TemplateRef<any>) {}
}
