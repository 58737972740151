import {Directive} from '@angular/core';
import {
  NG_VALIDATORS,
  Validator,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';

@Directive({
  standalone: true,
  selector: '[urlValidator]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: UrlValidatorDirective, multi: true},
  ],
})
export class UrlValidatorDirective implements Validator {
  constructor() {}

  validate(control: AbstractControl): ValidationErrors | null {
    return control.value ? urlValidator(control.value) : null;
  }
}

const urlValidator = (str: string): ValidationErrors | null => {
  let url;
  try {
    // eslint-disable-next-line
    url = new URL(str);
  } catch (error) {
    return {invalidUrl: true};
  }

  return null;
};
