import {Directive} from '@angular/core';
import {
  NG_VALIDATORS,
  Validator,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';

@Directive({
  standalone: true,
  selector: '[requiredPhotoValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: RequiredPhotoValidatorDirective,
      multi: true,
    },
  ],
})
export class RequiredPhotoValidatorDirective implements Validator {
  constructor() {}

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.value && typeof control.value === 'object') {
      if (control.value.ID === 0) {
        return {requiredPhoto: true};
      }
    }
    return null;
  }
}
