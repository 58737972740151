import {
  Directive,
  ElementRef,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';

@Directive({
  standalone: true,
  selector: '[appInViewport]',
})
export class InViewportDirective implements OnInit, OnDestroy {
  @Output() inViewport = new EventEmitter<boolean>();
  private observer!: IntersectionObserver;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    const options = {
      root: null, // Use the browser viewport
      rootMargin: '0px', // No margin
      threshold: 0, // Trigger when element is 0% visible
    };

    this.observer = new IntersectionObserver((entries, observer) => {
      this.inViewport.emit(entries[0].isIntersecting);
    }, options);

    this.observer.observe(this.el.nativeElement);
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }
}
