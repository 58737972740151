import {Directive, Input} from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
} from '@angular/forms';

@Directive({
  standalone: true,
  selector: '[appRequiredFileType]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: RequiredFileTypeDirective,
      multi: true,
    },
  ],
})
export class RequiredFileTypeDirective implements Validator {
  @Input('appRequiredFileType') fileTypes: string[] = [];

  validate(control: AbstractControl): ValidationErrors | null {
    const file = control.value;
    if (file) {
      if (!(file instanceof File)) {
        return {
          appRequiredFileType: 'Value is not a file',
        };
      }
      const split = file.name.split('.');
      const extension = split[split.length - 1].toLowerCase();
      if (!this.fileTypes.some(type => type === extension)) {
        return {
          appRequiredFileType: 'Unsupported File Type',
        };
      }
    }
    return null;
  }
}
